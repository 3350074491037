import React from "react";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { putBearerToken } from "../Apis";

function FooterLink(props) {
  const [footer_contact_us, set_footer_contact_us] = useState(props.footer_contact_us)
  const [footer_promotion, set_footer_promotion] = useState(props.footer_promotion)
  const [footer_offer, set_footer_offer] = useState(props.footer_offer)
  const [footer_tnc, set_footer_tnc] = useState(props.footer_tnc)
  const [footer_privacy_policy, set_footer_privacy_policy] = useState(props.footer_privacy_policy)
  const update = async () => {

    let json = {
      footer_contact_us: footer_contact_us,
      footer_promotion: footer_promotion,
      footer_offer: footer_offer,
      footer_tnc: footer_tnc,
      footer_privacy_policy: footer_privacy_policy,
    }


    putBearerToken("setting", json).then(result => {

      props.handlerender()

    }).catch(e => {
    })

  }
  return (
    <>
      <Row className="mb-4" id="footer">
        <Col md="12">
          <div class="card bg-yellow-100 border-0 shadow">
            <div class="card-header d-sm-flex flex-row align-items-center flex-0">
              <h2 class="fs-5 fw-bold mb-0">Footer Link</h2>
            </div>
            <div class="card-body p-4">
              <form action="" method="" enctype="">
                <input type="hidden" name="" value="" />{" "}
                <Row className="gy-2">
                  <Col md="3">
                    <label for="" class="form-label">
                      Contact Us
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      name="contact"
                      value={footer_contact_us}
                      onChange={(e) => set_footer_contact_us(e.target.value)}
                      style={{ color: "#000" }}
                      required=""
                    />
                  </Col>
                  <Col md="3">
                    <label for="" class="form-label">
                      Promotion
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      name="promotion"
                      value={footer_promotion}
                      onChange={(e) => set_footer_promotion(e.target.value)}
                      style={{ color: "#000" }}
                      required=""
                    />
                  </Col>
                  <Col md="3">
                    <label for="" class="form-label">
                      Offers
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      name="offers"
                      value={footer_offer}
                      onChange={(e) => set_footer_offer(e.target.value)}
                      style={{ color: "#000" }}
                      required=""
                    />
                  </Col>
                  <Col md="3">
                    <label for="" class="form-label">
                      T&C
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      name="terms"
                      value={footer_tnc}
                      onChange={(e) => set_footer_tnc(e.target.value)}
                      style={{ color: "#000" }}
                      required=""
                    />
                  </Col>
                  <Col md="3">
                    <label for="" class="form-label">
                      Privacy Policy
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      name="privacy"
                      value={footer_privacy_policy}
                      onChange={(e) => set_footer_privacy_policy(e.target.value)}
                      style={{ color: "#000" }}
                      required=""
                    />
                  </Col>

                  <Col md="12">
                    <button onClick={() => update()} type="button" class="btn btnSubmit mt-3">
                      Update
                    </button>
                  </Col>
                </Row>
              </form>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default FooterLink;
