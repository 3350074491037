import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import editimg from "../images/edit.png";
import deleteImage from "../images/delete.png";
import { deleteBearerToken, getBearerToken, getWithoutBearerToken, multipartPostBearerToken, postBearerToken } from "../Apis";
import { Constants } from "../Constants";
import MyModal from "./MyModal";

function Premium() {

  
  const [link, setLink] = useState("");
  const [image, setimage] = useState(null)
  const [showModal, setshowModal] = useState(false)
  const [item, setitem] = useState("")
  const [tag, settag] = useState("English")
  const openModal = (item) => {
    setitem(item)
    setshowModal(true)
  }
  const closeModal = () => {
    setrender(c => c + 1)
    setshowModal(false)
  }
  const [render, setrender] = useState(0)
  const [data, setdata] = useState([])
  React.useEffect(() => {
    fetch_data()
  }, [render,tag])


  const fetch_data = async () => {

    getWithoutBearerToken("section_by_type/premium_site").then(result => {

      setdata(result.data)

    })
  }

  const addNew = async () => {

    let formData = new FormData()
    formData.append('link', link)
    formData.append('file', image)
    formData.append('tag', tag)
    formData.append('type', "premium_site")

    multipartPostBearerToken("section", formData).then(result => {
      if (result.error_code == 200) {
        setLink("")
        settag("")
        setrender(c => c + 1)
      } else {
        setrender(c => c + 1)
      }

    })
  }
  const deleteNow = async (id) => {

    postBearerToken("section_delete", { _id: id }).then(result => {

      setrender(o => o + 1)

    })
  }


  return (
    <>
    <Row className="mb-4" id="all-premium-slide">
      <Col xs="12">
        <div className="card bg-yellow-100 border-0 shadow">
          <div className="card-header d-sm-flex flex-row align-items-center flex-0">
            <h2 className="fs-5 fw-bold mb-0">All Premium Site</h2>
          </div>
          <div className="card-body p-4">
            <Row>
              <Col xs="3">
                <div class="card border-0 shadow mb-3">
                  <div class="card-header d-sm-flex flex-row align-items-center flex-0">
                    <h2 class="fs-5 fw-bold mb-0"></h2>
                  </div>
                  <div class="card-body p-4">
                    <form action="" method="" enctype="" class="text-center">
                      <input type="hidden" name="" value="" />
                      <input
                        type="text"
                        class="form-control mb-2"
                        name="heading"
                        value={link}
                        onChange={(e) => setLink(e.target.value)}
                        placeholder="Heading"
                      />
                      <input
                        type="text"
                        class="form-control mb-2"
                        name="content"
                        value={tag}
                        onChange={(e) => settag(e.target.value)}
                        placeholder="Sub heading"
                      />

                      <input
                        type="file"
                        name="image"
                        id=""
                        onChange={e => setimage(e.target.files[0])}

                        class="form-control"
                      />
                      <button
                        onClick={() =>  addNew()}
                        type="button"
                        class="input-group-text btn-primary mt-2 d-flex btn btnSubmit"
                      >
                        Save
                      </button>
                    </form>
                  </div>
                </div>
              </Col>

              { data && data.length > 0 ? 
              data.map((item, index) => {
                return (
                  <Col xs="3" className="text-center mb-3" key={index}>
                    <div className="card border-0 shadow h-100 position-relative">
                      <div className="position-absolute top-0 end-0">
                        <Link onClick={() => openModal(item)} className="me-1">
                          <img src={editimg} alt="" />
                        </Link>
                        <Link onClick={() => deleteNow(item._id)}>
                          <img src={deleteImage} alt="" />
                        </Link>
                      </div>
                      <div className="card-header d-sm-flex flex-row align-items-center flex-0">
                        <h2 class="fs-5 fw-bold mb-0 text-dark">
                          Premium Site
                        </h2>
                      </div>
                      <div class="card-body p-4">
                        <img
                          src={Constants.BASEURL+item.image}
                          alt=""
                          class="rounded-pill mb-2"
                          width="100"
                        />
                        <h5 class="" style={{ color: "#000" }}>
                          {item.link}
                        </h5>
                        <p class="mb-2">{item.tag}</p>
                        <button
                          title="https://up247.in/"
                          class="btn"
                          style={{ backgroundColor: "#006d9f", color: "#fff" }}
                        >
                          Create Id
                        </button>
                      </div>
                    </div>
                  </Col>
                );
              })
              : null
              }
            </Row>
          </div>
        </div>
      </Col>
    </Row>
    <MyModal cond={showModal} tag={"tag"} _item={item} closeModal={closeModal} />

    </>
  );
}

export default Premium;
