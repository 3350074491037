import { useState } from "react";
import {
  Menu,
  MenuItem,
  Sidebar,
  SubMenu,
  useProSidebar,
} from "react-pro-sidebar";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartBar, faHome } from "@fortawesome/free-solid-svg-icons";

import Home from "../Pages/Home";
import logo from "../images/logo.png";
import { Link, useNavigate } from "react-router-dom";

function Sidebaar() {
  const navigate= useNavigate()
  const { collapseSidebar } = useProSidebar();
  const [selectedMenuItem, setSelectedMenuItem] = useState("Home");

  const handleMenuItemClick = (menuItem) => {
    setSelectedMenuItem(menuItem);
  };
  const logout = () => {
      localStorage.clear()
      navigate("/")
  }
  return (
    <>
      <div style={{ display: "flex", height: "100vh", minHeight: "400px" }}>
        <Sidebar backgroundColor="black">
          {/* <button
            className="btn bg-secondary text-white shadow-none"
            onClick={() => collapseSidebar()}
          >
            Collapse
          </button> */}
          <div className="adminLogo text-center">
            <img src={logo} alt="" className="img-fluid m-2" />
          </div>
          <Menu>
            <MenuItem
              className="sidebarMenuItem"
              onClick={() => handleMenuItemClick("Home")}
              style={{ margin: "0 20px", borderRadius: "0.5rem" }}
              icon={<FontAwesomeIcon icon={faHome} />}
            >
              Home
            </MenuItem>
            <li class="nav-item">
              <div style={{cursor:"pointer"}}
                onClick={() => logout()}
                class="btn btn-secondary d-flex align-items-center justify-content-center btn-upgrade-pro"
              >
                <span class="sidebar-icon d-inline-flex align-items-center justify-content-center"></span>
                <span>Sign Out</span>
              </div>
            </li>
          </Menu>
        </Sidebar>
        <main className="p-1 w-100 ">
          {/* <button
            className="btn bg-secondary text-white shadow-none"
            onClick={() => collapseSidebar()}
          >
            Collapse
          </button> */}
          <div className="p-1">
            {selectedMenuItem === "Home" && (
              <div>
                <Home />
              </div>
            )}
          </div>
        </main>
      </div>
    </>
  );
}

export default Sidebaar;
