import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import editimg from "../images/edit.png";
import deleteImage from "../images/delete.png";
import { deleteBearerToken, getBearerToken, getWithoutBearerToken, multipartPostBearerToken, postBearerToken } from "../Apis";
import { Constants } from "../Constants";
import MyModal from "./MyModal";

function BannerSlide() {

  const [link, setLink] = useState("");
  const [image, setimage] = useState(null)
  const [showModal, setshowModal] = useState(false)
  const [item, setitem] = useState(null)
  const openModal = (item) => {
    setitem(item)
    setshowModal(true)
  }
  const closeModal = () => {
    setrender(c=>c+1)
    setshowModal(false)
  }
  const [render, setrender] = useState(0)
  const [data, setdata] = useState([])
  React.useEffect(() => {
    fetch_data()
  }, [render])


  const fetch_data = async () => {

    getWithoutBearerToken("section_by_type/banner").then(result => {

      setdata(result.data)

    })
  }

  const addNew = async () => {

    let formData = new FormData()
    formData.append('link', link)
    formData.append('file', image)

    multipartPostBearerToken("section", formData).then(result => {
      if (result.error_code == 200) {
        setLink("")
        setrender(c => c + 1)
      } else {
        setrender(c => c + 1)
      }

    })
  }
  const deleteNow = async (id) => {

    postBearerToken("section_delete" , {_id : id}).then(result => {

      setrender(o=>o+1)

    })
  }

  return (
      <>
        <Row className="mb-4" id="banner-slide">
          <Col xs="12">
            <div className="card bg-yellow-100 border-0 shadow">
              <div className="card-header d-sm-flex flex-row align-items-center flex-0">
                <h2 className="fs-5 fw-bold mb-0">Banner Slide</h2>
              </div>
              <div className="card-body p-4">
                <Row>
                  <Col xs="4">
                    <div className="card border-0 shadow">
                      <div className="card-header d-sm-flex flex-row align-items-center flex-0">
                        <h2 className="fs-5 fw-bold mb-0"></h2>
                      </div>
                      <div className="card-body p-4">
                        <form
                          action=""
                          method=""
                          enctype=""
                          className="text-center"
                        >
                          <input type="hidden" name="" value="" />

                          <input
                            type="text"
                            className="form-control mb-2"
                            name="link"
                            placeholder="Create Id link"
                            value={link}
                            onChange={(event) => setLink(event.target.value)}
                          />
                          <input
                            type="file"
                            name="image"
                            id=""
                            className="form-control"
                            onChange={e=>setimage(e.target.files[0])}
                          />
                          <button
                            onClick={() => addNew()}
                            type="button"
                            className="input-group-text btn text-start d-flex  btnSubmit mt-2"
                          >
                            Save Slide
                          </button>
                        </form>
                      </div>
                    </div>
                  </Col>

                  {
                    data && data.length > 0 ?
                    data.map((item, index) => {
                      return (
                        <Col md="4" className="text-center mb-3" key={index}>
                          <div className="card border-0 shadow h-100 position-relative">
                            <div className="position-absolute top-0 end-0 mt-1">
                              <Link onClick={() => openModal(item)} className="me-1">
                                <img src={editimg} alt="" />
                              </Link>
                              <Link onClick={()=>deleteNow(item._id)}>
                                <img src={deleteImage} alt="" />
                              </Link>
                            </div>
                            <div className="card-header d-sm-flex flex-row align-items-center flex-0">
                              <h2
                                className="fs-5 fw-bold mb-0"
                                style={{ color: "#000" }}
                              >
                                {item.Cardtitle}
                              </h2>
                            </div>
                            <div className="card-body p-4">
                              <img
                                src={Constants.BASEURL + item.image}
                                alt=""
                                className=" mb-2 w-100"
                              />
                              <h5 className="" style={{ color: "#000" }}></h5>
                              <p className="mb-2"></p>
                              <button
                                title=""
                                className="btn  "
                                style={{
                                  backgroundColor: "#006d9f",
                                  color: "#fff",
                                }}
                              >
                                Create Id
                              </button>
                            </div>
                          </div>
                        </Col>
                      );
                    })
                    :
                    null
                    
                  }
                </Row>
              </div>
            </div>
          </Col>
        </Row>

        <MyModal cond = {showModal} _item = {item} closeModal={closeModal}/>
      </>
  );
}

export default BannerSlide;
