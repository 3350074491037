import React, { useState } from "react";
import { loginApi, postBearerToken } from "../Apis";
import { Constants } from "../Constants";
import { useNavigate } from "react-router-dom";

function Login() {
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");

    const navigate = useNavigate()

    const loginSubmit = () => {


        loginApi("login", {email : email , password : password}).then(result => {
            if (result.error_code == 200) {

                localStorage.setItem(Constants.JWT , result.data)
                navigate("/home")
                console.log(result)

            } else {

            }

        })

    };
  return (
    <>
      <section class="vh-lg-100 mt-md-5  mt-lg-0 bg-soft d-flex align-items-center LoginPage">
        <div class="container">
          <p class="text-center"></p>
          <div class="row justify-content-center form-bg-image">
            <div class="col-md-6 d-flex align-items-center justify-content-center ">
              <div class="bg-white shadow border-0 rounded border-light p-4 p-lg-5 w-100 fmxw-500 m-md-5 m-2">
                <div class="text-center text-md-center mb-4 mt-md-0">
                  <a
                    href="https://tamashabook.com"
                    class="d-flex align-items-center justify-content-center mb-3"
                  >
                    <svg
                      class="icon icon-xs me-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    Back to homepage
                  </a>
                  <h1 class="mb-0 h3" style={{ color: "#000" }}>
                    Admin Login
                  </h1>
                </div>
                <form action="" class="mt-4" method="POST">
                  <input type="hidden" name="" value="" />{" "}
                  <div class="form-group mb-4">
                    <label for="email">Your Email</label>
                    <div class="input-group">
                      <span class="input-group-text" id="basic-addon1">
                        <svg
                          class="icon icon-xs text-gray-600"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                          <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
                        </svg>
                      </span>
                      <input
                        type="email"
                        class="form-control"
                        placeholder="example@company.com"
                        id="email"
                        name="email"
                        autofocus=""
                        required=""
                        onChange={(event) => setEmail(event.target.value)}

                      />
                      <span class="text-danger"></span>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="form-group mb-4">
                      <label for="password">Your Password</label>
                      <div class="input-group">
                        <span class="input-group-text" id="basic-addon2">
                          <svg
                            class="icon icon-xs text-gray-600"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                              clip-rule="evenodd"
                            ></path>
                          </svg>
                        </span>
                        <input
                          type="password"
                          placeholder="Password"
                          name="password"
                          class="form-control"
                          id="password"
                          required=""
                          onChange={(event) => setPassword(event.target.value)}

                        />
                        <span class="text-danger"></span>
                      </div>
                    </div>
                    {/* <div class="d-flex justify-content-between align-items-top mb-4">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="remember"
                        />
                        <label class="form-check-label mb-0" for="remember">
                          Remember me
                        </label>
                      </div>
                    </div> */}
                  </div>
                  <div class="d-grid">
                    <button onClick={() => loginSubmit()} type="button" class="btn btn-gray-800">
                      Sign in
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Login;