import React, { useState } from "react";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { multipartPutBearerToken } from '../Apis';

function MyModal(props) {
  // console.log(props)
  const [link, setLink] = React.useState(props._item ? props._item.link : "")
  const [tag, settag] = React.useState(props._item ? props._item.tag : "")
  const [image, setimage] = React.useState(null)
  const update = async () => {

    let formData = new FormData()
    formData.append('link', link)

    if(props.tag){
      formData.append('tag', tag)
    }
    formData.append('file', image)
    formData.append('_id', props._item._id)

    multipartPutBearerToken("section", formData).then(result => {
      if (result.error_code == 200) {
        props.closeModal()
      } else {
        props.closeModal()
      }

    })
  }
  return (
    <Modal
      show={props.cond}
      onHide={() => props.closeModal()}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Update
          {/* {JSON.stringify(props)} */}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="card border-0 shadow">
          <div className="card-header d-sm-flex flex-row align-items-center flex-0">
            <h2 className="fs-5 fw-bold mb-0"></h2>
          </div>
          <div className="card-body p-4">
            <form
              action=""
              method=""
              enctype=""
              className="text-center"
            >
              <input type="hidden" name="" value="" />

              <input
                type="text"
                className="form-control mb-2"
                name="link"
                placeholder={props.tag ? "Heading" :"Create Id link"}
                value={link}
                onChange={(event) => setLink(event.target.value)}
              />
              {
                props.tag
                  ?
                  <input
                    type="text"
                    className="form-control mb-2"
                    name="link"
                    placeholder="Sub Heading"
                    value={tag}
                    onChange={(event) => settag(event.target.value)}
                  />
                  :
                  null
              }
              <input
                type="file"
                name="image"
                id=""
                className="form-control"
                onChange={e => setimage(e.target.files[0])}
              />

            </form>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary"
          onClick={() => props.closeModal()}
        >
          Close
        </Button>
        <Button onClick={() => update()} variant="primary">Update</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default MyModal;