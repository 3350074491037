import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import deleteImage from "../images/delete.png";


import { deleteBearerToken, getBearerToken, getWithoutBearerToken, multipartPostBearerToken, postBearerToken } from "../Apis";
import { Constants } from "../Constants";
import MyModal from "./MyModal";

function Partner() {

  const [link, setLink] = useState("");
  const [image, setimage] = useState(null)
  const [showModal, setshowModal] = useState(false)
  const [item, setitem] = useState("")
  const openModal = (item) => {
    setitem(item)
    setshowModal(true)
  }
  const closeModal = () => {
    setrender(c => c + 1)
    setshowModal(false)
  }
  const [render, setrender] = useState(0)
  const [data, setdata] = useState([])
  React.useEffect(() => {
    fetch_data()
  }, [render])


  const fetch_data = async () => {

    getWithoutBearerToken("section_by_type/partner").then(result => {

      setdata(result.data)

    })
  }

  const addNew = async (img) => {

    let formData = new FormData()
    formData.append('link', "link")
    formData.append('file', img)
    formData.append('type', "partner")

    multipartPostBearerToken("section", formData).then(result => {
      if (result.error_code == 200) {
        setLink("")
        setrender(c => c + 1)
      } else {
        setrender(c => c + 1)
      }

    })
  }
  const deleteNow = async (id) => {

    postBearerToken("section_delete", { _id: id }).then(result => {

      setrender(o => o + 1)

    })
  }

  return (
    <>
      <Row className="mb-4" id="partner">
        <Col xs="12">
          <div class="card bg-yellow-100 border-0 shadow">
            <div class="card-header ">
              <Row className="align-items-center">
                <Col md="5">
                  <h2 className="fs-5 fw-bold">Our Partners</h2>
                </Col>
                <Col md="7">
                  <div className="text-end">
                    <form action="" method="" enctype="">
                      <input type="hidden" name="" value="" />
                      <label for="partnerimg" class="btn btnSubmit">
                        Add Partners
                      </label>
                      <input
                        type="file"
                        class="d-none"
                        id="partnerimg"
                        name="image"
                        onChange={(e) => addNew(e.target.files[0])}
                      />
                    </form>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="card-body p-4">
              {data && data.length > 0 ?
                <Row>
                  {data.map((item, index) => {
                    return (
                      <Col xs="2">
                        <div class="position-relative">
                          <div class="position-absolute top-0 end-0">
                            <Link onClick={()=>deleteNow(item._id)}>
                              <img src={deleteImage} alt="" />
                            </Link>
                          </div>
                          <img
                            class="w-100"
                            src={Constants.BASEURL+item.image}
                            alt=""
                          />
                        </div>
                      </Col>
                    );
                  })}
                </Row>
                :
                null
              }
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default Partner;
