import React from "react";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { putBearerToken } from "../Apis";

function Social(props) {

  const [social_whatsapp, set_social_whatsapp] = useState(props.social_whatsapp)
  const [social_facebook, set_social_facebook] = useState(props.social_facebook)
  const [social_instagram, set_social_instagram] = useState(props.social_instagram)
  const [social_telegram, set_social_telegram] = useState(props.social_telegram)
  const [social_youtube, set_social_youtube] = useState(props.social_youtube)
  const [social_twitter, set_social_twitter] = useState(props.social_twitter)



  const update = async () => {

    let json = {
      social_whatsapp: social_whatsapp,
      social_facebook: social_facebook,
      social_instagram: social_instagram,
      social_telegram: social_telegram,
      social_youtube: social_youtube,
      social_twitter: social_twitter,
    }


    putBearerToken("setting", json).then(result => {

      props.handlerender()

    }).catch(e => {
    })

  }



  return (
    <>
      <Row className="mb-4 " id="socials">
        <Col md="12">
          <div class="card bg-yellow-100 border-0 shadow">
            <div class="card-header d-sm-flex flex-row align-items-center flex-0">
              <h2 class="fs-5 fw-bold mb-0">Social</h2>
            </div>
            <div class="card-body p-4">
              <form action="" method="" enctype="">
                <input type="hidden" name="" value="" />{" "}
                <Row className="gy-2">
                  <Col md="3">
                    <label for="" class="form-label">
                      whatsapp
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      name="whatsapp"
                      value={social_whatsapp}
                      onChange={(e) => set_social_whatsapp(e.target.value)}
                      style={{ color: "#000" }}
                      required=""
                    />
                  </Col>
                  <Col md="3">
                    <label for="" class="form-label">
                      facebook
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      name="facebook"
                      value={social_facebook}
                      onChange={(e) => set_social_facebook(e.target.value)}
                      style={{ color: "#000" }}
                      required=""
                    />
                  </Col>
                  <Col md="3">
                    <label for="" class="form-label">
                      instagram
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      name="instagram"
                      value={social_instagram}
                      onChange={(e) => set_social_instagram(e.target.value)}
                      style={{ color: "#000" }}
                      required=""
                    />
                  </Col>
                  <Col md="3">
                    <label for="" class="form-label">
                      telegram
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      name="telegram"
                      value={social_telegram}
                      onChange={(e) => set_social_telegram(e.target.value)}
                      style={{ color: "#000" }}
                      required=""
                    />
                  </Col>
                  <Col md="3">
                    <label for="" class="form-label">
                      youtube
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      name="youtube"
                      value={social_youtube}
                      onChange={(e) => set_social_youtube(e.target.value)}
                      style={{ color: "#000" }}
                      required=""
                    />
                  </Col>
                  <Col md="3">
                    <label for="" class="form-label">
                      twitter
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      name="twitter"
                      value={social_twitter}
                      onChange={(e) => set_social_twitter(e.target.value)}
                      style={{ color: "#000" }}
                      required=""
                    />
                  </Col>
                  <Col md="12">
                    <button onClick={() => update()} type="button" class="btn btnSubmit mt-3">
                      Update
                    </button>
                  </Col>
                </Row>
              </form>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default Social;
