import axios from 'axios'
import { Constants } from './Constants';

// export const BASE_URL = "http://localhost:8000/";
export const BASE_URL = "https://tamashabook.com:8000/";

export const loginApi = async (endpoint, data) => {
    return await axios.post(BASE_URL + endpoint, data).then((result) => {
        if (result.data.error_code == 200) {
            console.log("Success", result.data)
            return result.data
        } else {
            console.log("Error", result.data)
            return result.data
        }
    }).catch(e => {
        console.log("Error", e)
        return e.response
    });
}
export const otpApi = async (endpoint, data) => {
    return await axios.get(BASE_URL + endpoint).then((result) => {
        if (result.data.error_code == 0) {
            console.log("Success", result)
            return result.data
        } else {
            console.log("Error", result.data)
            return 500
        }
    }).catch(e => {
        console.log("Error", e)
        return 500
    });
}

export const postBearerToken = async (endpoint, data) => {
    let token = localStorage.getItem(Constants.JWT)
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    return await axios.post(BASE_URL + endpoint, data, config).then((result) => {
        return result.data
    }).catch(e => {
        return 500
    });
}
export const putBearerToken = async (endpoint, data) => {
    let token = localStorage.getItem(Constants.JWT)
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    return await axios.put(BASE_URL + endpoint, data, config).then((result) => {
        return result.data
    }).catch(e => {
        return 500
    });
}
export const getBearerToken = async (endpoint) => {
    let token = localStorage.getItem(Constants.JWT)
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    return await axios.get(BASE_URL + endpoint, config).then((result) => {
        return result.data
    }).catch(e => {
        return 500
    });
}
export const getWithoutBearerToken = async (endpoint) => {
    
    return await axios.get(BASE_URL + endpoint).then((result) => {
        return result.data
    }).catch(e => {
        return 500
    });
}

export const multipartPostBearerToken = async (endpoint, data) => {
    let token = localStorage.getItem(Constants.JWT)
    const config = {
        "Content-Type": "multipart/form-data",
        headers: { Authorization: `Bearer ${token}` }
    };
    return await axios.post(BASE_URL + endpoint, data, config).then((result) => {
        return result.data
    }).catch(e => {
        return 500
    });
}
export const multipartPutBearerToken = async (endpoint, data) => {
    let token = localStorage.getItem(Constants.JWT)
    const config = {
        "Content-Type": "multipart/form-data",
        headers: { Authorization: `Bearer ${token}` }
    };
    return await axios.put(BASE_URL + endpoint, data, config).then((result) => {
        return result.data
    }).catch(e => {
        return 500
    });
}