import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import editimg from "../images/edit.png";
import deleteImage from "../images/delete.png";

import { deleteBearerToken, getBearerToken, getWithoutBearerToken, multipartPostBearerToken, postBearerToken } from "../Apis";
import { Constants } from "../Constants";
import MyModal from "./MyModal";


function Celebrities() {


    const [link, setLink] = useState("");
    const [image, setimage] = useState(null)
    const [showModal, setshowModal] = useState(false)
    const [item, setitem] = useState("")
    const openModal = (item) => {
        setitem(item)
        setshowModal(true)
    }
    const closeModal = () => {
        setrender(c => c + 1)
        setshowModal(false)
    }
    const [render, setrender] = useState(0)
    const [data, setdata] = useState([])
    React.useEffect(() => {
        fetch_data()
    }, [render])


    const fetch_data = async () => {

        getWithoutBearerToken("section_by_type/influencer").then(result => {

            setdata(result.data)

        })
    }

    const addNew = async () => {

        let formData = new FormData()
        formData.append('link', link)
        formData.append('file', image)
        formData.append('type', "influencer")

        multipartPostBearerToken("section", formData).then(result => {
            if (result.error_code == 200) {
                setLink("")
                setrender(c => c + 1)
            } else {
                setrender(c => c + 1)
            }

        })
    }
    const deleteNow = async (id) => {

        postBearerToken("section_delete", { _id: id }).then(result => {

            setrender(o => o + 1)

        })
    }

    return (
        <>
            <Row className="mb-4" id="our-casino">
                <Col xs="12">
                    <div class="card bg-yellow-100 border-0 shadow">
                        <div class="card-header ">
                            <Row>
                                <Col md="5">
                                    <h2 className="fs-5 fw-bold  w-100">Celebrities and Influencer</h2>
                                </Col>
                                <Col md="7">

                                    <div className="w-100 text-end">
                                        <form action="" method="" enctype="">
                                            <input type="hidden" name="" value="" />
                                            <div class="input-group mb-3">
                                                <input type="file"
                                                    onChange={e => setimage(e.target.files[0])}

                                                    class="form-control" name="image" />
                                                <input
                                                    type="text"
                                                    class="input-group-text m-0 form-control shadow-none bg-white"
                                                    name="link"
                                                    value={link}
                                                    onChange={(e) => setLink(e.target.value)}
                                                    placeholder="Enter your Link"
                                                />
                                                <button
                                                    onClick={() => addNew()}

                                                    type="button"
                                                    class="input-group-text m-0 btn btnSubmit"
                                                >
                                                    Add Celebrities and Influencer
                                                </button>
                                            </div>
                                        </form>
                                    </div>

                                </Col>
                            </Row>
                        </div>
                        {data && data.length > 0 ?
                            <div className="card-body p-4">
                                <Row>
                                    {data.map((item, index) => {
                                        return (
                                            <Col xs="2" className="mb-3" key={index}>
                                                <div class="position-relative">
                                                    <div style={{ zIndex: "9999" }} class="position-absolute top-0 end-0">
                                                        <Link onClick={() => openModal(item)} className="me-1">
                                                            <img src={editimg} alt="" />
                                                        </Link>
                                                        <Link onClick={() => deleteNow(item._id)}>
                                                            <img src={deleteImage} alt="" />
                                                        </Link>
                                                    </div>
                                                    {/* <img
                            title=""
                            src={Constants.BASEURL+item.image}
                            alt=""
                            class="w-100 rounded"
                          /> */}
                                                    <video
                                                        controls
                                                        class="rounded"
                                                        width="100%"
                                                        height="100%"
                                                    >
                                                        <source
                                                            src={Constants.BASEURL + item.image}
                                                            type="video/mp4"
                                                        />
                                                    </video>
                                                </div>
                                            </Col>
                                        );
                                    })}
                                </Row>
                            </div>
                            : null}

                    </div>
                </Col>
            </Row>
            <MyModal cond={showModal} _item={item} closeModal={closeModal} />
        </>

    );
}

export default Celebrities;
