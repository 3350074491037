import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import EnglishBox from "../Component/EnglishBox";
import HindiBox from "../Component/HindiBox";
import KannadaBox from "../Component/KannadaBox";
import TelguBox from "../Component/TelguBox";
import { deleteBearerToken, getBearerToken, getWithoutBearerToken, multipartPostBearerToken, postBearerToken } from "../Apis";
import { Constants } from "../Constants";
import MyModal from "./MyModal";
import editimg from "../images/edit.png";
import deleteImage from "../images/delete.png";

function HowToStart() {



  const [link, setLink] = useState("");
  const [image, setimage] = useState(null)
  const [showModal, setshowModal] = useState(false)
  const [item, setitem] = useState("")
  const [tag, settag] = useState("English")
  const openModal = (item) => {
    setitem(item)
    setshowModal(true)
  }
  const closeModal = () => {
    setrender(c => c + 1)
    setshowModal(false)
  }
  const [render, setrender] = useState(0)
  const [data, setdata] = useState([])
  React.useEffect(() => {
    fetch_data()
  }, [render,tag])


  const fetch_data = async () => {

    getWithoutBearerToken("section_by_type/how_to_start").then(result => {

      setdata(result.data.filter(i => i.tag == tag))

    })
  }

  const addNew = async () => {

    let formData = new FormData()
    formData.append('link', link)
    formData.append('file', image)
    formData.append('tag', tag)
    formData.append('type', "how_to_start")

    multipartPostBearerToken("section", formData).then(result => {
      if (result.error_code == 200) {
        setLink("")
        setrender(c => c + 1)
      } else {
        setrender(c => c + 1)
      }

    })
  }
  const deleteNow = async (id) => {

    postBearerToken("section_delete", { _id: id }).then(result => {

      setrender(o => o + 1)

    })
  }

  return (
    <>
      <Row className="mb-4" id="how_to_start">
        <Col xs="12">
          <div className="card bg-yellow-100 border-0 shadow">
            <div className="card-header d-sm-flex flex-row align-items-center flex-0">
              <h2 className="fs-5 fw-bold mb-0">HOW TO START</h2>
            </div>
            <div className="card-body p-4">
              <Row>
                <Col xs="12">

                  <ul
                    className="nav gap-3 nav-pills mb-3"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active btn btnSubmit"
                        // id="pills-home-tab"
                        // data-bs-toggle="pill"
                        // data-bs-target="#pills-home"
                        // type="button"
                        // role="tab"
                        // aria-controls="pills-home"
                        // aria-selected="true"
                        onClick={() => settag("English")}
                      >
                        English
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link btn btnSubmit"
                        // id="pills-profile-tab"
                        // data-bs-toggle="pill"
                        // data-bs-target="#pills-profile"
                        // type="button"
                        // role="tab"
                        // aria-controls="pills-profile"
                        // aria-selected="false"
                        onClick={() => settag("Hindi")}

                      >
                        Hindi
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link btn btnSubmit"
                        onClick={() => settag("Kannada")}

                      // id="pills-contact-tab"
                      // data-bs-toggle="pill"
                      // data-bs-target="#pills-contact"
                      // type="button"
                      // role="tab"
                      // aria-controls="pills-contact"
                      // aria-selected="false"
                      >
                        Kannada
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link btn btnSubmit"
                        onClick={() => settag("Telugu")}

                      // id="pills-telgu-tab"
                      // data-bs-toggle="pill"
                      // data-bs-target="#pills-telgu"
                      // type="button"
                      // role="tab"
                      // aria-controls="pills-telgu"
                      // aria-selected="false"
                      >
                        Telgu{" "}
                      </button>
                    </li>
                  </ul>
                </Col>
                <Col xs="12">


                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className="tab-pane fade show active "
                      id="pills-home"
                      role="tabpanel"
                      aria-labelledby="pills-home-tab"
                    >

                      <>
                        <form action="" method="" enctype="">
                          <input type="hidden" name="" value="" />
                          <div className="input-group mb-3">
                            <input type="file"
                              onChange={e => setimage(e.target.files[0])}
                              className="form-control" name="image" />
                            <input
                              type="text"
                              className="input-group-text m-0 form-control bg-white"
                              name="link"
                              value={link}
                              onChange={(e) => setLink(e.target.value)}
                              placeholder="Enter your Link"
                            />
                            <button
                              onClick={() => addNew()}
                              type="button"
                              className="input-group-text btn btnSubmit m-0 btn-primary"
                            >
                              Add {tag}
                            </button>
                          </div>
                        </form>
                        {data && data.length > 0 ? 
                        <Row>
                          {data.map((item) => {
                            return (
                              <Col xs="3">
                                <div className="position-relative">
                                  <div className="position-absolute top-0 end-0">
                                    <Link onClick={() => openModal(item)} className="me-1">
                                      <img src={editimg} alt="" />
                                    </Link>
                                    <Link onClick={() => deleteNow(item._id)}>
                                      <img src={deleteImage} alt="" />
                                    </Link>
                                  </div>
                                  <img
                                    title=""
                                    src={Constants.BASEURL+item.image}
                                    alt=""
                                    className="rounded img-fluid"
                                  />
                                </div>
                              </Col>
                            );
                          })}
                        </Row>
                        :
                        null }
                      </>

                    </div>
                    {/* <div
                      className="tab-pane fade"
                      id="pills-profile"
                      role="tabpanel"
                      aria-labelledby="pills-profile-tab"
                    >
                      <HindiBox />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="pills-contact"
                      role="tabpanel"
                      aria-labelledby="pills-contact-tab"
                    >
                      <KannadaBox />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="pills-telgu"
                      role="tabpanel"
                      aria-labelledby="pills-telgu-tab"
                    >
                      <TelguBox /> */}
                    {/* </div> */}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
      <MyModal cond={showModal} _item={item} closeModal={closeModal} />

    </>
  );
}

export default HowToStart;
