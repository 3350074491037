
import './App.css';
import Sidebaar from './Layout/Sidebaar';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Login from './Pages/Login';



function App() {
  return (
    <>

      <BrowserRouter>
        <Routes>
          <Route path='/home' element={<Sidebaar />} />
          <Route path='/' element={<Login />} />

        </Routes>
      </BrowserRouter>

    </>
  );
}

export default App;
