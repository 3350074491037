import React, { useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import BannerSlide from "../Layout/BannerSlide";
import OurCasino from "../Layout/OurCasino";
import LiveCasino from "../Layout/LiveCasino";
import FantasyGame from "../Layout/FantasyGame";
import SlotGames from "../Layout/SlotGames";
import Premium from "../Layout/Premium";
import HowToStart from "../Layout/HowToStart";
import Service from "../Layout/Service";
import Partner from "../Layout/Partner";
import Promotion from "../Layout/Promotion";
import Celebrities from "../Layout/Celebrities";
import Responsible from "../Layout/Responsible";
import Social from "../Layout/Social";
import FooterLink from "../Layout/FooterLink";
import { Payment } from "@mui/icons-material";
import PaymentMethod from "../Layout/PaymentMethod";
import { getBearerToken, getWithoutBearerToken, putBearerToken } from "../Apis";
import { Constants } from "../Constants";
import MyModal from "../Layout/MyModal";

function Home() {

  const [header_end, set_header_end] = useState("")
  const [whats_app_number, set_whats_app_number] = useState("")
  const [marquee, set_marquee] = useState("")
  const [login_link, set_login_link] = useState("")
  const [register_link, set_register_link] = useState("")
  const [social_whatsapp, set_social_whatsapp] = useState("")
  const [social_facebook, set_social_facebook] = useState("")
  const [social_instagram, set_social_instagram] = useState("")
  const [social_telegram, set_social_telegram] = useState("")
  const [social_youtube, set_social_youtube] = useState("")
  const [social_twitter, set_social_twitter] = useState("")
  const [footer_contact_us, set_footer_contact_us] = useState("")
  const [footer_promotion, set_footer_promotion] = useState("")
  const [footer_offer, set_footer_offer] = useState("")
  const [footer_tnc, set_footer_tnc] = useState("")
  const [footer_privacy_policy, set_footer_privacy_policy] = useState("")
  const [render, setrender] = useState(0)
  const [data, setdata] = useState(null)

  const [logo, setlogo] = useState([])
  const [favicon, setfavicon] = useState([])

  const handleRender = () => {
    setrender(o => o + 1)
  }
  const [showModal, setshowModal] = useState(false)
  const [item, setitem] = useState(null)
  const openModal = (item) => {
    setitem(item)
    setshowModal(true)
  }
  const closeModal = () => {
    setrender(c => c + 1)
    setshowModal(false)
  }

  React.useEffect(() => {
    fetch_data()
  }, [render])

  const fetch_data = async () => {
    console.log("ssss")
    getBearerToken("setting").then(result => {

      set_header_end(result.data.header_end)
      set_whats_app_number(result.data.whats_app_number)
      set_marquee(result.data.marquee)
      set_login_link(result.data.login_link)
      set_register_link(result.data.register_link)
      set_social_whatsapp(result.data.social_whatsapp)
      set_social_facebook(result.data.social_facebook)
      set_social_instagram(result.data.social_instagram)
      set_social_telegram(result.data.social_telegram)
      set_social_youtube(result.data.social_youtube)
      set_social_twitter(result.data.social_twitter)
      set_footer_contact_us(result.data.footer_contact_us)
      set_footer_promotion(result.data.footer_promotion)
      set_footer_offer(result.data.footer_offer)
      set_footer_tnc(result.data.footer_tnc)
      set_footer_privacy_policy(result.data.footer_privacy_policy)
      setdata(result.data)


    })
    getWithoutBearerToken("section_by_type/logo").then(result => {

      setlogo(result.data)

    })
    getWithoutBearerToken("section_by_type/favicon").then(result => {

      setfavicon(result.data)

    })

  }



  const update = async () => {

    let json = {
      header_end: header_end,
      whats_app_number: whats_app_number,
      marquee: marquee,
      login_link: login_link,
      register_link: register_link
    }


    putBearerToken("setting", json).then(result => {

      handleRender()

    }).catch(e => {
    })

  }

  return (
    data ?
      <>
        <section>
          <Container fluid>
            <Row className="gy-4">
              <Col xs="12" md="3">
                <div className="card bg-yellow-100 border-0 shadow mb-3">
                  <div className="card-header d-sm-flex flex-row align-items-center flex-0">
                    <h2 className="fs-5 fw-bold mb-0">Logo</h2>
                  </div>
                  <div className="card-body p-4">
                    <form action="" className="text-center" method="" encType="">
                      <input type="hidden" name="" value="" />
                      <img
                        src={logo && logo.length > 0 ? Constants.BASEURL + logo[0].image : ""}
                        alt=""
                        className="rounded img-fluid"
                      />
                      <label onClick={() => openModal(logo[0])} for="dsadsa" class="btn btnSubmit mt-3">
                        Update logo
                      </label>
                    </form>
                  </div>
                </div>
                <div class="card bg-yellow-100 border-0 shadow">
                  <div class="card-header d-sm-flex flex-row align-items-center flex-0">
                    <h2 class="fs-5 fw-bold mb-0">Favicon</h2>
                  </div>
                  <div class="card-body p-4">
                    <form action="" class="text-center" method="" enctype="">
                      <input type="hidden" name="" value="" />{" "}
                      <img
                        src={favicon && favicon.length > 0 ? Constants.BASEURL + favicon[0].image : ""}
                        alt=""
                        class="rounded w-25"
                      />
                      <br />
                      <label onClick={() => openModal(favicon[0])} for="dsadsa" class="btn btnSubmit mt-3">
                        Update logo
                      </label>
                      {/* <input
                        type="file"
                        class="d-none"
                        id="dsadsa"
                        name="image"
                        onChange={(e) => e.target.form.submit()}
                      /> */}
                    </form>
                  </div>
                </div>
              </Col>
              <Col xs="12" md="9" className="mb-4 h-100">
                <div className="card bg-yellow-100 border-0 shadow">
                  <div className="card-header d-sm-flex flex-row align-items-center flex-0">
                    <h2 className="fs-5 fw-bold mb-0">Setting</h2>
                  </div>
                  <div className="card-body p-4">
                    <Row>
                      <form action="" method="" enctype="">
                        <input type="hidden" name="" value="" />{" "}
                        <Col xs="12">
                          <div class="col-12">
                            <label for="" class="form-label">
                              Header End
                            </label>
                            <textarea
                              class="form-control"
                              name="header"
                              rows="4"
                              onChange={(e) => set_header_end(e.target.value)}
                              value={header_end}
                            >
                              {header_end}
                            </textarea>
                          </div>
                        </Col>
                        <Col xs="12">
                          <label for="" className="form-label">
                            whatsapp Number
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name=""
                            value={whats_app_number}
                            onChange={(e) =>
                              set_whats_app_number(e.target.value)
                            }
                          />
                        </Col>
                        <Col xs="12" className=" text-end">
                          <button onClick={() => update()} type="button" className="btn btnSubmit mt-2">
                            Update Setting
                          </button>
                        </Col>
                      </form>
                    </Row>
                  </div>
                </div>
              </Col>

              <Col xs="12" className="mb-4 h-100">
                <div class="card bg-yellow-100 border-0 shadow">
                  <div class="card-header d-sm-flex flex-row align-items-center flex-0">
                    <h2 class="fs-5 fw-bold mb-0">Marquee</h2>
                  </div>
                  <div class="card-body p-4">
                    <form action="" method="" enctype="">
                      <input type="hidden" name="" value="" />{" "}
                      <div class="input-group mb-3">
                        <input
                          type="text"
                          class="form-control"
                          name="heading"
                          value={marquee}
                          onChange={(e) => set_marquee(e.target.value)}
                        />
                        <button
                          onClick={() => update()} type="button" class="input-group-text btn btnSubmit"
                        >
                          Update Marquee
                        </button>
                      </div>
                    </form>
                    <Row>
                      <Col xs="6">
                        <label for="" class="form-label">
                          Login
                        </label>
                        <form class="" action="" method="" enctype="">
                          {" "}
                          <input type="hidden" name="" value="" />
                          <div class="input-group mb-3">
                            <input
                              type="text"
                              class="form-control"
                              name="heading"
                              value={login_link}
                              onChange={(event) =>
                                set_login_link(event.target.value)
                              }
                            />
                            <button
                              onClick={() => update()} type="button"
                              class="input-group-text btn btnSubmit"
                            >
                              Update link
                            </button>
                          </div>
                        </form>
                      </Col>
                      <Col xs="6">
                        <label for="" class="form-label">
                          Register
                        </label>
                        <form class="" action="" method="" enctype="">
                          {" "}
                          <input type="hidden" name="" value="" />{" "}
                          <div class="input-group mb-3">
                            <input
                              type="text"
                              class="form-control"
                              name="heading"
                              value={register_link}
                              onChange={(event) =>
                                set_register_link(event.target.value)
                              }
                            />
                            <button
                              onClick={() => update()} type="button"
                              class="input-group-text btn btnSubmit"
                            >
                              Update link
                            </button>
                          </div>
                        </form>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>

            <BannerSlide />

            <OurCasino />

            <LiveCasino />
            <FantasyGame />
            <SlotGames />

            <Premium />
            <HowToStart />
            <Service />
            <Partner />

            <Promotion />
            <Celebrities />
            <Responsible />

            <Social
              social_whatsapp={social_whatsapp}
              social_facebook={social_facebook}
              social_instagram={social_instagram}
              social_telegram={social_telegram}
              social_youtube={social_youtube}
              social_twitter={social_twitter}
              handlerender={handleRender}
            />

            <FooterLink
              footer_contact_us={footer_contact_us}
              footer_promotion={footer_promotion}
              footer_offer={footer_offer}
              footer_tnc={footer_tnc}
              footer_privacy_policy={footer_privacy_policy}
              handlerender={handleRender}
            />
            <PaymentMethod />
          </Container>
        </section>
        <MyModal cond={showModal} _item={item} closeModal={closeModal} />

      </>
      : null
  );
}

export default Home;
